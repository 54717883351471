const createPaymentIntent = (options) => {
  return window
    .fetch(`/api/create-payment-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(options),
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        // console.log("API error:", { data });
        throw new Error("PaymentIntent API Error");
      } else {
        return {
          clientSecret: data.client_secret,
          id: data.id,
        }
      }
    });
};


const updatePaymentIntent = (options) => {
  return window
    .fetch(`/api/update-payment-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(options),
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      // console.log(data);
      if (!data || data.error) {
        // console.log("API error:", { data });
        throw new Error("PaymentIntent API Error");
      }
      //  else {
      //   return data.client_secret;
      // }
    });
};

const getProductDetails = (options) => {
  return window
    .fetch(`/api/product-details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        // console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data;
      }
    });
};

const getProducts = (options) => {
  return window
    .fetch(`/api/get-products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        // console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data;
      }
    });
};

const getPublicStripeKey = (options) => {
  return window
    .fetch(`/api/public-key`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        // console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data.publishableKey;
      }
    });
};

const api = {
  createPaymentIntent,
  getProducts,
  getPublicStripeKey: getPublicStripeKey,
  getProductDetails: getProductDetails,
  updatePaymentIntent,
};

export default api;
