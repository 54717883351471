import React from "react";
import { AppBar, Toolbar, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  topAppBar: {
    // Height: "20vh",
    // minHeight: "230px",
    backgroundColor: 'transparent',
  },
  toolbar: {
    alignItems: "flex-start",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    verticalAlign: "middle",
    display: "inline-flex"
  }
}));

const AppBars = ({ title, logoLink }) => {
  const classes = useStyles();

  return (
    <AppBar
      position='static'
      elevation={0}
      color='default'
      className={classes.topAppBar}
    >
      <Toolbar className={classes.toolbar}>
        {title !== undefined && (
          <Grid
            container
            item
            direction='row'
            alignItems='center'
            justify='center'
            xs={12}
            sm={12}
          >
            <Grid
              item
              container
              direction='row'
              alignItems='center'
              justify='center'
              xs={12}
              sm={12}
            >
              {logoLink !== undefined && (
                <img src={`./${logoLink}`} alt='logo' height='50px' />
              )}
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                variant='h4'
                align='center'
                style={{
                  fontWeight: "600",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                  fontSize: "1.5rem",
                }}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppBars;
