import React from "react";
import { StateProvider } from "./StateContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./constants/theme";

import Header from "./Views/Header";
import Main from "./Views/Main";

import api from "./api";
const stripePromise = api.getPublicStripeKey().then((key) => loadStripe(key));

const App = () => (
  <ThemeProvider theme={theme}>
    <StateProvider>
      <Elements stripe={stripePromise}>
        <div style={{ flexGrow: 1 }}>
          <Header
            title='Welcome! Create your account to get started.'
            logoLink='knapsack-logo.svg'
            style={{
              justifyContent: "center",
            }}
          />
          <Main />
        </div>
      </Elements>
    </StateProvider>
  </ThemeProvider>
);


export default App;
