import React, { useState, useEffect } from "react";
import { TextField, Grid, Typography, Checkbox } from "@material-ui/core";
import { FormControlLabel, FormControl } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useStateValue } from "../../StateContext";
import api from "../../api";

const ServiceForm = () => {
  const [{ formValues }, dispatch] = useStateValue();

  const [activeProducts, setActiveProducts] = useState(null);


  useEffect(() => {
    async function getActiveProducts(){
      const products = await api.getProducts();

      if (products){
        setActiveProducts(products);
      }
      // console.log(products);
    }

    getActiveProducts();
  }, []);



  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant='h6'
          style={{
            marginTop: "1rem",
          }}
        >
          Plan and Workspace Information
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          paddingTop: "0",
        }}
      >
        <FormControl
          component='fieldset'
          // error={errors.knapsackPlan ? true : false}
        >
          <FormHelperText id='knapsack-plan-helper' required>
            Select Knapsack Plan
          </FormHelperText>

          <RadioGroup
            // component={RadioGroup}
            name='knapsackPlan'
            required
            onChange={(e) =>
              dispatch({
                type: "editFormValue",
                key: "knapsackplan",
                value: e.target.value,
              })
            }
            value={formValues.knapsackplan}
            // value={values.knapsackPlan}
            // touched={touched.knapsackPlan}
          >
            {activeProducts && (
              <>
                {activeProducts.map((product) => (
                  <FormControlLabel
                    key={product.id}
                    value={product.id}
                    control={<Radio required color='primary' />}
                    label={`${product.name} - $${product.price ?? "0.00"}`}
                  />
                ))}
              </>
            )}
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          type='text'
          id='workspacename'
          label='Preferred Workspace Name'
          placeholder='acme-corp'
          variant='outlined'
          onChange={(e) =>
            dispatch({
              type: "editFormValue",
              key: "workspacename",
              value: e.target.value,
            })
          }
          value={formValues.workspacename}
          required
          fullWidth={true}
          helperText='(app.knapsack.cloud/site/YOUR-WORKSPACE-NAME)'
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          type='text'
          id='promo'
          label='Promo Code'
          // placeholder='PROMOCODE'
          variant='outlined'
          fullWidth={true}
          onChange={(e) =>
            dispatch({
              type: "editFormValue",
              key: "promo",
              value: e.target.value,
            })
          }
        />
      </Grid>
    </>
  );
};

export default ServiceForm;
