import { responsiveFontSizes, createMuiTheme } from "@material-ui/core";

let theme = {
  overrides: {
    MuiTypography: {
      body1: {
        color: "#4C5566",
      },
    },
    MuiFormHelperText: {
      root: {
        color: "rgba(46, 50, 58)",
        fontWeight: "600",
      },
    },
    MuiInputLabel: {
      asterisk: {
        display: "none",
      },
      outlined: {
        color: "rgba(46, 50, 58)",
        opacity: "0.6",
        fontWeight: "400",
        transform: "translate(14px, 16px) scale(1)",
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%',
        "&$shrink": {
          opacity: "1",
          fontWeight: "600",
          transform: "translate(14px, 4px) scale(0.7)",
        },
      },
    },
    MuiOutlinedInput: {
      // hover: {
      //   borderColor: 'red'
      // },
      notchedOutline: {
        transition: 'all 0.15s ease',
        borderColor: "transparent",
        borderWidth: "0",
      },
      // focused: {
      //   "& $notchedOutline": {
      //     borderColor: "green",
      //   },
      // },
      input: {
        padding: "20px 14px 12px 14px",
        borderRadius: "10px",
      },
      root: {
        borderRadius: "10px",

        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#d8cdef",
        },

        "&$focused": {
          "& $notchedOutline": {
            boxShadow: "0 0 1px 2px #d8cdef, inset 0 0 1px 1px #54319d",
            borderWidth: "0px",
            // top: "0",
          },
        },

        // "&:$focused $notchedOutline": {
        //   borderColor: "green",
        // },

        // "& $focused": {
        // },
        // 0 0 1px 2px #d8cdef, inset 0 0 1px 1px #54319d
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "white",
        borderRadius: "10px",
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: "600",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#2e323a",
      },
    },
    MuiButton: {
      root: {
        fontWeight: "700",
        textTransform: "initial",
        fontSize: "1.3rem",
        lineHeight: "1.4",
        padding: "0.5rem 2rem",
        borderRadius: "15px",
      },
    },
  },
  typography: {
    fontFamily: [
      "Source Sans Pro",
      // "Roboto",
    ],
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#f9f9f9",
    },
    primary: {
      light: "#7dafc2",
      main: "#54319d",
      dark: "#4c2c8d",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff4081",
      main: "#f50057",
      dark: "#c51162",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "#2f3747",
      secondary: "rgb(76 85 102 / .8)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
};

theme = createMuiTheme(theme);
theme = responsiveFontSizes(theme);
export default theme;
