import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress
} from "@material-ui/core";
import {
  SentimentVerySatisfied,
  SentimentVeryDissatisfied
} from "@material-ui/icons";
import StepperIcons from "./StepperIcons";
import ContactForm from "./Forms/ContactForm";
import PaymentForm from "./Forms/PaymentForm";
import ServiceForm from "./Forms/ServiceForm";
import {
  useStripe,
  useElements,
  CardCvcElement
} from "@stripe/react-stripe-js";
import { useStateValue } from "../StateContext";
import StepConnector from "./StepConnector";
import {
  // clientSecretPull,
  stripeDataObjectConverter,
  // clientSecretDataObjectConverter
} from "../constants/functions";
import api from "../api";

// OVERALL STYLE
const style = makeStyles((theme) => ({
  fowardButton: {
    // marginRight: theme.spacing(2),
    textTransform: "initial"
  },
  backButton: {
    marginRight: theme.spacing(2),
    textTransform: "initial"
  },
  mainBox: {
    position: "relative",
    borderRadius: '20px',
    padding: "2.5rem 2rem",
    background: theme.palette.background.default
  },
  stepper: {
    height: "auto"
    // minHeight: "60px"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  buttonWrapper: {
    justifyContent: "flex-end"
  }
}));

const StepContent = ({ step }) => {
  switch (step) {
    case 0:
      return <ContactForm />;
    case 1:
      return <ServiceForm />;
    case 2:
      return <PaymentForm />;
    default:
      return <></>;
  }
};

const Steppers = () => {
  const classes = style();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cardStatus, setCardStatus] = useState(true);
  const [cardMessage, setCardMessage] = useState("");

  const [amount, setAmount] = useState(1);
  const [currency, setCurrency] = useState("usd");
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentId, setPaymentId] = useState(null);

  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [{ formValues }, dispatch] = useStateValue();


  // useEffect(() => {
    
  // }, [activeStep, formValues]);

  async function finishSubmitting(){
          

    setCardStatus(false);
    setProcessing(true);

    setLoading(true);

    const cardElement = elements.getElement(CardCvcElement);
    const stripeDataObject = stripeDataObjectConverter(formValues, cardElement);
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      clientSecret,
      stripeDataObject
    );

    if (error) {
      setCardStatus(false);
      setCardMessage(error.message);
      setError(`Payment failed: ${error.message}`);
      setProcessing(false);
      console.log("[error]", error);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setCardStatus(true);
      setSucceeded(true);
      setProcessing(false);
      setError(null);
      setCardMessage("");
      dispatch({ type: "emptyFormValue" });
    }
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setLoading(false);
  }


  useEffect(() => {
    async function handlePreSubmit () {
      // console.log(formValues);
      // Step 1: Fetch product details such as amount and currency from
      // API to make sure it can't be tampered with in the client.
      // api.getProductDetails().then((productDetails) => {
      //   setAmount(productDetails.amount / 100);
      //   setCurrency(productDetails.currency);
      // });

      await api
        .createPaymentIntent({
          // promo: formValues.promo,
          payment_method_types: ["card"],
        })
        .then(({ clientSecret, id }) => {
          // console.log(response);
          // console.log(clientSecret);
          setClientSecret(clientSecret);
          setPaymentId(id);
          // await finishSubmitting();
        })
        .catch((err) => {
          setError(err.message);
        });


      // if (formValues.promo){
      //   // Step 2: Create PaymentIntent over Stripe API
      //   await api
      //     .createPaymentIntent({
      //       promo: formValues.promo,
      //       payment_method_types: ["card"],
      //     })
      //     .then((clientSecret) => {
      //       console.log(clientSecret);
      //       setClientSecret(clientSecret);
      //       // await finishSubmitting();
      //     })
      //     .catch((err) => {
      //       setError(err.message);
      //     });
      // } else {
      
    };

    handlePreSubmit();
  }, []);




  async function handleSubmit() {
    console.log(paymentId, formValues.knapsackplan);

    await api
      .updatePaymentIntent({
        paymentId,
        productId: formValues.knapsackplan,
        promo: formValues.promo,
        metadata: {
          fullName: formValues.fullname,
          emailAddress: formValues.email,
          workspaceName: formValues.workspacename,
          planSelected: formValues.knapsackplan,
          promoCode: formValues.promo ?? "",
        },
      })
      .then((result) => {
        finishSubmitting();
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const renderSuccess = () => {
    return (
      <Box className={classes.mainBox}>
        <Grid
          container
          spacing={0}
          direction='column'
          // justify='space-around'
          alignItems='center'
          style={{ height: "400px" }}
        >
          <Typography
            variant='h2'
            style={{
              fontWeight: "700",
              fontFamily: "Source Sans Pro",
              marginTop: "1rem",
              marginBottom: "2rem",
              fontSize: "3rem",
              lineHeight: "1.2",
            }}
          >
            Success!
          </Typography>

          <div>
            <p
              style={{
                fontFamily: "Source Sans Pro",
                fontSize: "1.2rem",
                lineHeight: "1.4",
              }}
            >
              Payment confirmation and access instructions will be sent to the
              email address provided.
            </p>

            <p
              style={{
                fontFamily: "Source Sans Pro",
                fontSize: "1.2rem",
                lineHeight: "1.4",
              }}
            >
              If you have any questions in the meantime, reach out to{" "}
              <a
                style={{
                  color: "#54319d",
                }}
                href='mailto:help@knapsack.cloud?subject=Knapsack Signup Question'
              >
                help@knapsack.cloud
              </a>
              !
            </p>
          </div>
        </Grid>
      </Box>
    );
  };

  // const stripe = useStripe();
  // const elements = useElements();

  // const handleNext = (e) => {
  //   if (activeStep === 2) {
  //     handleSubmit(e);
  //   } else {
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   }
  // };
  // const handleBack = () =>
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // const handleReset = () => setActiveStep(0);

  // const capture = async () => {
  //   setLoading(true);

  //   const clientSecretDataObject = clientSecretDataObjectConverter(formValues);
  //   const clientSecret = await clientSecretPull(clientSecretDataObject);
  //   const cardElement = elements.getElement(CardCvcElement);
  //   const stripeDataObject = stripeDataObjectConverter(formValues, cardElement);
  //   const { paymentIntent, error } = await stripe.confirmCardPayment(
  //     clientSecret,
  //     stripeDataObject
  //   );

  //   if (error) {
  //     setCardStatus(false);
  //     setCardMessage(error.message);
  //   } else if (paymentIntent && paymentIntent.status === "succeeded") {
  //     setCardStatus(true);
  //     setCardMessage("");
  //     dispatch({ type: "emptyFormValue" });
  //   }
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setLoading(false);
  // };

  const renderForm = () => {
    return (
      // <form onSubmit={handleSubmit}>
      //   <h1>
      //     {currency.toLocaleUpperCase()}{" "}
      //     {amount.toLocaleString(navigator.language, {
      //       minimumFractionDigits: 2
      //     })}{" "}
      //   </h1>
      //   <h4>Pre-order the Pasha package</h4>

      //   <div className="sr-combo-inputs">
      //     <div className="sr-combo-inputs-row">
      //       <input
      //         type="text"
      //         id="name"
      //         name="name"
      //         placeholder="Name"
      //         autoComplete="cardholder"
      //         className="sr-input"
      //       />
      //     </div>

      //     <div className="sr-combo-inputs-row">
      //       <CardElement
      //         className="sr-input sr-card-element"
      //         options={options}
      //       />
      //     </div>
      //   </div>

      //   {error && <div className="message sr-field-error">{error}</div>}

      // <button
      //   className="btn"
      //   disabled={processing || !clientSecret || !stripe}
      // >
      //   {processing ? "Processing…" : "Pay"}
      // </button>
      // </form>
      <>
        <Box className={classes.mainBox}>
          <form
            autoComplete='on'
            className={classes.form}
            onSubmit={(e) => {
              e.preventDefault();
              // console.log('click');
              handleSubmit();
            }}
          >
            <Grid container spacing={3}>
              {/* <Typography variant='h5'>User information</Typography> */}
              <ContactForm />

              {/* <Typography variant='h5'>Plan + Workspace information</Typography> */}
              <ServiceForm />

              {/* <Typography variant='h5'>Payment information</Typography> */}

              <PaymentForm />

              {cardMessage && (
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    style={{
                      color: "#E95550",
                      lineHeight: "1.4",
                      fontSize: "1rem",
                    }}
                  >
                    {cardMessage}
                  </Typography>
                </Grid>
              )}

              <Grid
                container
                item
                justify='flex-end'
                style={{
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.forwardButton}
                  disableRipple
                  type='submit'
                  fullWidth
                  disabled={loading}
                  // disabled={processing || !clientSecret || !stripe}
                >
                  {loading ? (
                    <CircularProgress color='inherit' size={24} />
                  ) : (
                    "Create Account"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </>
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {/* {renderSuccess()} */}
        {succeeded ? renderSuccess() : renderForm()}
      </div>
    </div>
  );
};

export default Steppers;
