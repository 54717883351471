import React from "react";
import { Container, Paper, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MultiStepStepper from "./MultiStepStepper";
import SingleStepStepper from "./SingleStepStepper";


const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    marginBottom: "55px",
    // minHeight: "calc(26vh + 260px)",
    maxWidth: "600px",
    marginLeft: "auto",
    marginRight: "auto"
  },
  container: {
    position: "relative",
    zIndex: "1100",
    // marginTop: "-95px",
    marginBottom: "45px"
  }
}));

const Main = () => {
  const classes = useStyles();

  return (
    <Box component='main' className={classes.boxWrapper}>
      <Container maxWidth='md' className={classes.container}>
        <Paper
          elevation={0}
          className='ks-c-shadow-frame'
          style={{
            borderRadius: "20px",
            border: '1px solid rgba(209,210,213,.5)',
            // boxShadow: "0 2rem 2rem rgba(84,49,157,.3), 0 0 0 0 transparent",
          }}
        >
          {/* <MultiStepStepper /> */}
          <SingleStepStepper />
        </Paper>
      </Container>
    </Box>
  );
};

export default Main;
