import Axios from "axios";

const localHost = window.location.hostname === "localhost";

const baseUrl = localHost
  ? "http://localhost/"
  : window.location.hostname + "/"; //'https://example.com'

// const devPublishableKeyGet = () => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         data: {
//           publishableKey:
//             "pk_test_51HkbdqDPyDuXiQ2vK3pdkzZohFlqlWB9HFnV0A2TwnzY739nu0ueYt43LpSvmvJyo4qaZGrjC60lg33Deiv7nlKO00uWArMeH8"
//         }
//       });
//     }, 50);
//   });
// };

// export const publishableKeyGet = () => {
//   const url = baseUrl + "/publishableKeyGet.php";
//   return new Promise(async (resolve) => {
//     const {
//       data: { publishableKey }
//     } = localHost ? await devPublishableKeyGet() : await Axios.get(url);
//     resolve(publishableKey);
//   });
// };

export const clientSecretPull = (data) => {
  const url = baseUrl + "capture.php";
  return new Promise(async (resolve) => {
    const {
      data: { clientSecret }
    } = await Axios.post(url, data);
    resolve(clientSecret);
  });
};

export const clientSecretDataObjectConverter = ({
  fullname,
  //   arrivaldate,
  //   service,
  //   fsm,
  knapsackplan,
  workspacename,
  email,
  //   fullname,
  //   email: receipt_email,
  currency,
  amount
}) => ({
  amount: 1,
  currency: "usd",
  cardType: "card",
  receipt_email: email,
  metadata: {
    fullname,
    knapsackplan,
    workspacename,
    email
    // arrivaldate,
    // service,
    // fsm
  }
});

export const stripeDataObjectConverter = (
  { fullname, knapsackplan, email, workspacename, promo },
  cardElement
) => ({
  // discounts: [
  //   {
  //     coupon: promotion_code,
  //   },
  // ],
  payment_method: {
    card: cardElement,
    metadata: {
      fullname,
      knapsackplan,
      workspacename,
      email,
      promo_code: promo,
      // arrivaldate,
      // service,
      // fsm
    },
    billing_details: {
      // address: {
      //   city,
      //   country: country.code,
      //   line1,
      //   line2,
      //   postal_code,
      //   state: null
      // },
      email,
      name: `${fullname}`,
      // phone: null,
    },
  },
});

export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name) => {
  document.cookie = name + "=; Max-Age=-99999999;";
};
