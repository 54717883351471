import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress
} from "@material-ui/core";
import {
  SentimentVerySatisfied,
  SentimentVeryDissatisfied
} from "@material-ui/icons";
import StepperIcons from "./StepperIcons";
import ContactForm from "./Forms/ContactForm";
import PaymentForm from "./Forms/PaymentForm";
import ServiceForm from "./Forms/ServiceForm";
import {
  useStripe,
  useElements,
  CardCvcElement
} from "@stripe/react-stripe-js";
import { useStateValue } from "../StateContext";
import StepConnector from "./StepConnector";
import {
  // clientSecretPull,
  stripeDataObjectConverter,
  // clientSecretDataObjectConverter
} from "../constants/functions";
import api from "../api";

// OVERALL STYLE
const style = makeStyles((theme) => ({
  fowardButton: {
    // marginRight: theme.spacing(2),
    textTransform: "initial"
  },
  backButton: {
    marginRight: theme.spacing(2),
    textTransform: "initial"
  },
  mainBox: {
    position: "relative",
    marginTop: "-3px",
    padding: "16px 24px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    background: theme.palette.background.default
  },
  stepper: {
    height: "auto"
    // minHeight: "60px"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  buttonWrapper: {
    justifyContent: "flex-end"
  }
}));

const StepContent = ({ step }) => {
  switch (step) {
    case 0:
      return <ContactForm />;
    case 1:
      return <ServiceForm />;
    case 2:
      return <PaymentForm />;
    default:
      return <></>;
  }
};

const Steppers = () => {
  const classes = style();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cardStatus, setCardStatus] = useState(true);
  const [cardMessage, setCardMessage] = useState("");

  const [amount, setAmount] = useState(1);
  const [currency, setCurrency] = useState("usd");
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [{ formValues }, dispatch] = useStateValue();


  useEffect(() => {
    if (activeStep !== 2) return;

    // Step 1: Fetch product details such as amount and currency from
    // API to make sure it can't be tampered with in the client.
    api.getProductDetails().then((productDetails) => {
      setAmount(productDetails.amount / 100);
      setCurrency(productDetails.currency);
    });

    // Step 2: Create PaymentIntent over Stripe API
    api
      .createPaymentIntent({
        promo: formValues.promo,
        payment_method_types: ["card"],
      })
      .then((clientSecret) => {
        setClientSecret(clientSecret);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [activeStep, formValues]);


  const handleSubmit = async (ev) => {
    // ev.preventDefault();
    setProcessing(true);

    setLoading(true);

    // const clientSecretDataObject = clientSecretDataObjectConverter(formValues);
    // const clientSecret = await clientSecretPull(clientSecretDataObject);
    const cardElement = elements.getElement(CardCvcElement);
    cardElement.update({
      style: {
        fontFamily: "'Source Sans Pro', sans-serif",
        opacity: 0.42,
        color: 'inherit'
      }
    });
    const stripeDataObject = stripeDataObjectConverter(formValues, cardElement);
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      clientSecret,
      stripeDataObject
    );

    if (error) {
      setCardStatus(false);
      setCardMessage(error.message);
      setError(`Payment failed: ${error.message}`);
      setProcessing(false);
      console.log("[error]", error);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setCardStatus(true);
      setSucceeded(true);
      setProcessing(false);
      setError(null);
      setCardMessage("");
      dispatch({ type: "emptyFormValue" });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setLoading(false);

    // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
    // const payload = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //     billing_details: {
    //       name: ev.target.name.value
    //     }
    //   }
    // });

    // if (error) {
    //   setError(`Payment failed: ${error.message}`);
    //   setProcessing(false);
    //   console.log("[error]", error);
    // } else {
    //   setError(null);
    //   setSucceeded(true);
    //   setProcessing(false);
    //   setMetadata(paymentIntent);
    //   console.log("[PaymentIntent]", paymentIntent);
    // }
  };

  const renderSuccess = () => {
    return (
      <Box className={classes.mainBox}>
        <Grid
          container
          spacing={0}
          direction='column'
          // justify='space-around'
          alignItems='center'
          style={{ height: "400px" }}
        >
          <Typography
            variant='h2'
            style={{
              fontWeight: "700",
              fontFamily: "Source Sans Pro",
              marginTop: "1rem",
              marginBottom: "2rem",
              fontSize: "3rem",
              lineHeight: "1.2",
            }}
          >
            Success!
          </Typography>

          <div>
            <p
              style={{
                fontFamily: "Source Sans Pro",
                fontSize: "1.2rem",
                lineHeight: "1.4",
              }}
            >
              Payment confirmation and access instructions will be sent to the
              email address provided.
            </p>

            <p
              style={{
                fontFamily: "Source Sans Pro",
                fontSize: "1.2rem",
                lineHeight: "1.4",
              }}
            >
              If you have any questions in the meantime, reach out to{" "}
              <a
                style={{
                  color: "#54319d",
                }}
                href='mailto:help@knapsack.cloud?subject=Knapsack Signup Question'
              >
                help@knapsack.cloud
              </a>
              !
            </p>
          </div>
        </Grid>
      </Box>
    );
  };

  // const stripe = useStripe();
  // const elements = useElements();

  const handleNext = (e) => {
    if (activeStep === 2) {
      handleSubmit(e);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleReset = () => setActiveStep(0);

  // const capture = async () => {
  //   setLoading(true);

  //   const clientSecretDataObject = clientSecretDataObjectConverter(formValues);
  //   const clientSecret = await clientSecretPull(clientSecretDataObject);
  //   const cardElement = elements.getElement(CardCvcElement);
  //   const stripeDataObject = stripeDataObjectConverter(formValues, cardElement);
  //   const { paymentIntent, error } = await stripe.confirmCardPayment(
  //     clientSecret,
  //     stripeDataObject
  //   );

  //   if (error) {
  //     setCardStatus(false);
  //     setCardMessage(error.message);
  //   } else if (paymentIntent && paymentIntent.status === "succeeded") {
  //     setCardStatus(true);
  //     setCardMessage("");
  //     dispatch({ type: "emptyFormValue" });
  //   }
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setLoading(false);
  // };

  const renderForm = () => {
    return (
      // <form onSubmit={handleSubmit}>
      //   <h1>
      //     {currency.toLocaleUpperCase()}{" "}
      //     {amount.toLocaleString(navigator.language, {
      //       minimumFractionDigits: 2
      //     })}{" "}
      //   </h1>
      //   <h4>Pre-order the Pasha package</h4>

      //   <div className="sr-combo-inputs">
      //     <div className="sr-combo-inputs-row">
      //       <input
      //         type="text"
      //         id="name"
      //         name="name"
      //         placeholder="Name"
      //         autoComplete="cardholder"
      //         className="sr-input"
      //       />
      //     </div>

      //     <div className="sr-combo-inputs-row">
      //       <CardElement
      //         className="sr-input sr-card-element"
      //         options={options}
      //       />
      //     </div>
      //   </div>

      //   {error && <div className="message sr-field-error">{error}</div>}

      // <button
      //   className="btn"
      //   disabled={processing || !clientSecret || !stripe}
      // >
      //   {processing ? "Processing…" : "Pay"}
      // </button>
      // </form>
      <>
        <Stepper
          alternativeLabel
          className={classes.stepper}
          connector={<StepConnector />}
          activeStep={activeStep}
        >
          {/* Change the number of loops here based on StepContent */}
          {[1, 2, 3].map((e) => (
            <Step key={e}>
              <StepLabel StepIconComponent={StepperIcons} />
            </Step>
          ))}
        </Stepper>
        <Box className={classes.mainBox}>
          {activeStep === 3 ? (
            <Grid
              container
              spacing={3}
              direction='column'
              justify='space-around'
              alignItems='center'
              style={{ height: "400px" }}
            >
              {cardStatus ? (
                <SentimentVerySatisfied fontSize='large' color='primary' />
              ) : (
                <SentimentVeryDissatisfied fontSize='large' color='error' />
              )}
              <Typography variant='h4'>{cardMessage}</Typography>
              <Button
                onClick={cardStatus ? handleReset : handleBack}
                className={classes.button}
              >
                {cardStatus ? "Reset" : "Back"}
              </Button>
            </Grid>
          ) : (
            <form
              autoComplete='on'
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault();
                handleNext();
              }}
            >
              <Grid container spacing={3}>
                <StepContent step={activeStep} />
                <Grid container item justify='flex-end'>
                  {activeStep !== 0 && (
                    <Button
                      disabled={activeStep === 0}
                      className={classes.backButton}
                      disableRipple
                      onClick={handleBack}
                    >
                      {activeStep === 1
                        ? "Back to User Info"
                        : "Back to Plan + Workspace"}
                    </Button>
                  )}

                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.forwardButton}
                    disableRipple
                    type='submit'
                    // disabled={loading}
                    // disabled={processing || !clientSecret || !stripe}
                  >
                    {loading ? (
                      <CircularProgress color='inherit' size={24} />
                    ) : activeStep === 2 && !processing ? (
                      "Start Building"
                    ) : activeStep === 2 && processing ? (
                      "Processing..."
                    ) : activeStep === 0 ? (
                      "Workspace Info"
                    ) : (
                      "Payment Info"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Box>
      </>
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {/* {renderSuccess()} */}
        {succeeded ? renderSuccess() : renderForm()}
      </div>
    </div>
  );
};

export default Steppers;
